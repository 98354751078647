import React from 'react';
import './App.css';

import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import Buttons from "./components/Footer/FooterBar";

// Artist
import Artists from "./components/ArtistPage/Artists";
import ArtistGallery from "./components/ArtistPage/ArtistGallery";
import ArtistEx from "./components/ArtistPage/ArtistEx";

// Exhibition
import Exhibition from "./components/Exhibitions/Exhibition";
import EventsSlider from "./components/Exhibitions/UpcomingEx";
import Ex_Gallery from "./components/Exhibitions/Ex_Gallery";
import Ex_Feature from "./components/Exhibitions/Ex_Feature";

// Main
import OctavateGallery from "./components/Home/OctavateGallery";
import FeatureCollection from "./components/Home/FeatureCollection";
import FeatureEx from "./components/Home/FeatureEx";
import Publication from "./components/Home/Publication";
import HomeEventsSlider from "./components/Home/Upcoming";
import ViewCollection from "./components/Home/ViewCollection";
import ViewArtist from "./components/Home/ViewArtist";
import FeatureThisWeek from "./components/Home/FeatureThisWeek";
import TrendingPieces from "./components/Home/TrendingPieces";
import Join from "./components/Home/JoinGallery";

// Shop
import Shop from "./components/Shop/Shop"
import CuratedForYou from "./components/Shop/Curate";
import AdmiredWork from "./components/Shop/AdmiredWork";
import RenownedArtist from "./components/Shop/RenownedArtist";
import NewRecognized from "./components/Shop/NewRecognized";

// Collect
import FeatureThisWeekCollect from "./components/Collect/FeatureThisWeekCollect";
import Collect from "./components/Collect/Collect";
import CollectGallery from "./components/Collect/CollectGallery";

// About
import About from "./components/About/About";
import AboutContent from "./components/About/AboutContent";
import OurWork from "./components/About/OurWork";
import Location from "./components/About/Location"

// Contact
import ContactUs from "./components/Contact/ContactUs";
import StayConnected from "./components/Contact/StayConnected";
import SendMsg from "./components/Contact/SendMsg";

//Checkout & Individual Art Piece
import ShoppingCart from "./components/Checkout/ShoppingCart";
import ArtPiece from "./components/IndividualArtPiece/ArtPiece";
import AboutArtPiece from "./components/IndividualArtPiece/AboutArtPiece";
import Interest from "./components/IndividualArtPiece/Interest";

// Collect
import Ex_FeatureCollect from "./components/Collect/Ex_FeatureCollect";

// Individual Artiest Piece
import ArtistHeader from "./components/IndividualArtistPage/ArtistHeader";
import AboutArtist from "./components/IndividualArtistPage/AboutArtist";
import ArtistExhibition from "./components/IndividualArtistPage/ArtistExhibition";
import AboutArtistArt from "./components/IndividualArtPiece/AboutArtist";

// Video
import Video from "./components/Video/Video";
import PrivateGallery from "./components/Video/OurPrivateGallery";

// Term of Use
import TermOfUse from "./components/TermOfUse/TermOfUse"

// Private Policy
import PrivatePolicy from "./components/PrivatePolicy/PrivatePolicy";

// FAQ
import FAQ from "./components/FAQ/FAQ";

// Checkout
import Cards from "./components/Payment/Cards";
import BillingAddress from "./components/Checkout/BillingAddress";
import BillingDetail from "./components/Checkout/BillingDetail";

// Setting
import Dashboard from "./components/Setting/Dashboard";
import AccountBar from "./components/Setting/AccountBar";
import Notif from "./components/Setting/Notification";
import Account from "./components/Setting/Account";
import ChangePass from "./components/Setting/ChangPass";

import Scroll from "./components/ScrollToTop/Scroll";
import {BrowserRouter as Router, Routes, Route, BrowserRouter} from 'react-router-dom';
import BillAndPay from "./components/Setting/BillAndPay";
import Colls from "./components/Setting/AccountCollections";
import Help from "./components/Setting/Help";

import CheckSummary from "./components/Checkout/CheckSummary";
import CheckThankYou from "./components/Checkout/CheckThankYou";

import Login from "./components/LoginAndRegister/Login"
import Register from "./components/LoginAndRegister/Register";
import {GoogleOAuthProvider} from "@react-oauth/google";
import MediaQuery from "react-responsive";
import Ex_Join from "./components/Exhibitions/EX_Join_mobile";
import ShopSideBar from "./components/Shop/ShopSideBar";

const clientID = "964649670305-3hq4nei1snuh8pgbl72aij5v2lqv9ajk.apps.googleusercontent.com";

class App extends React.Component{
  render() {

      return (
          <BrowserRouter>
              <Routes>
                  {/*<Route path="/" element={*/}
                  {/*    <GoogleOAuthProvider clientId={clientID}>*/}
                  {/*      <GoogleLogin/>*/}
                  {/*    </GoogleOAuthProvider>*/}
                  {/*} />*/}

                  <Route path="Login" element={
                      <GoogleOAuthProvider clientId={clientID}>
                          <Scroll/>
                          <NavBar/>
                          <Login/>
                      </GoogleOAuthProvider>
                  } />

                  <Route path="Register" element={
                      <GoogleOAuthProvider clientId={clientID}>>
                          <Scroll/>
                          <NavBar/>
                          <Register/>
                      </GoogleOAuthProvider>
                  } />

                  <Route path="/" element={
                      <div>
                          <Scroll/>
                          <NavBar/>
                          <OctavateGallery/>
                          <FeatureCollection/>
                          <FeatureEx/>
                          <Publication/>
                          <HomeEventsSlider/>
                          <ViewCollection/>
                          <ViewArtist/>
                          <FeatureThisWeek/>
                          <TrendingPieces/>
                          <Join/>
                          <Footer/>
                      </div>
                  }/>

                  <Route path="Artists" element={
                      <div>
                          <Scroll/>
                          <NavBar/>
                          <Artists/>
                          <ArtistGallery/>
                          <ArtistEx/>
                          <Footer/>
                      </div>
                  }/>

                  <Route path="Exhibitions" element={
                      <div>
                          <Scroll/>
                          <NavBar/>
                          <Exhibition/>
                          <EventsSlider/>
                          <Ex_Gallery/>
                          <Ex_Feature/>
                          <MediaQuery minWidth={1024}>
                              <Join/>
                          </MediaQuery>
                          <MediaQuery maxWidth={1024}>
                              <Ex_Join/>
                          </MediaQuery>
                          <Footer/>
                      </div>
                  } />

                  <Route path="Shop" element={
                      <div>
                          <Scroll/>
                          <NavBar/>
                          <Shop/>
                          <CuratedForYou/>
                          <AdmiredWork/>
                          <RenownedArtist/>
                          <NewRecognized/>
                          <Footer/>
                      </div>
                  } />

                  <Route path="ShopSideBar" element={
                      <div>
                          <ShopSideBar/>
                      </div>
                  } />

                  <Route path="Collect" element={
                      <div>
                          <Scroll/>
                          <NavBar/>
                          <Collect/>
                          <FeatureThisWeekCollect/>
                          <Ex_FeatureCollect/>
                          <CollectGallery/>
                          <div style={{height: "10vh"}}></div>
                          <Join/>
                          <Footer/>
                      </div>
                  } />

                  <Route path="About" element={
                      <div>
                          <Scroll/>
                          <NavBar/>
                          <About/>
                          <AboutContent/>
                          <OurWork/>
                          <Location/>
                          <Join/>
                          <Footer/>
                      </div>
                  } />

                  <Route path="ContactUs" element={
                      <div>
                          <Scroll/>
                          <NavBar/>
                          <ContactUs/>
                          <StayConnected/>
                          <SendMsg/>
                          <Join/>
                          <Footer/>
                      </div>
                  } />

                  <Route path="IndividualArtist" element={
                      <div>
                          <Scroll/>
                          <NavBar/>
                          <ArtistHeader/>
                          <AboutArtist/>
                          <ArtistExhibition/>
                          <Footer/>
                      </div>
                  } />

                  <Route path="IndividualArt" element={
                      <div>
                          <Scroll/>
                          <NavBar/>
                          <ArtPiece/>
                          <AboutArtPiece/>
                          <AboutArtistArt/>
                          <Interest/>
                          <Footer/>
                      </div>
                  } />

                  <Route path="Video" element={
                      <div>
                          <Scroll/>
                          <NavBar/>
                          <Video/>
                          <PrivateGallery/>
                          <Footer/>
                      </div>
                  } />

                  <Route path="TermOfUse" element={
                      <div>
                          <Scroll/>
                          <NavBar/>
                          <TermOfUse/>
                          <Footer/>
                      </div>
                  } />

                  <Route path="PrivatePolicy" element={
                      <div>
                          <Scroll/>
                          <NavBar/>
                          <PrivatePolicy/>
                          <Footer/>
                      </div>
                  } />

                  <Route path="FAQ" element={
                      <div>
                          <Scroll/>
                          <NavBar/>
                          <FAQ/>
                          <Footer/>
                      </div>
                  } />

                  <Route path="Checkout" element={
                      <div>
                          <Scroll/>
                          <NavBar/>
                          <MediaQuery minWidth={1024}>
                              <div style={{display: "flex"}}>
                                  <div style={{width: "42vw", height: "160vh"}}>
                                      <ShoppingCart/>
                                  </div>
                                  <div>
                                      <Cards/>
                                      <BillingDetail/>
                                      <BillingAddress/>
                                  </div>
                              </div>
                          </MediaQuery>
                          <MediaQuery maxWidth={1024}>
                              <div style={{marginLeft: "5vw"}}>
                                  <ShoppingCart/>
                                  <Cards/>
                                  <BillingDetail/>
                                  <BillingAddress/>
                              </div>
                          </MediaQuery>
                          <div style={{height: "28vh", width: "100vw", backgroundColor: "#F1F1F1"}}>
                          </div>
                          <Footer/>
                      </div>
                  } />

                  <Route path="Checkout/Detail" element={
                      <div>
                          <Scroll/>
                          <NavBar/>
                          <MediaQuery minWidth={1024}>
                              <div style={{display: "flex"}}>
                                  <div style={{width: "42vw", height: "89vh"}}>
                                      <ShoppingCart/>
                                  </div>
                                  <div>
                                      <CheckSummary/>
                                  </div>
                              </div>
                          </MediaQuery>
                          <MediaQuery maxWidth={1024}>
                              <div style={{marginLeft: "5vw"}}>
                                  <ShoppingCart/>
                                  <CheckSummary/>
                              </div>
                          </MediaQuery>
                          <Footer/>
                      </div>
                  } />

                  <Route path="Checkout/Detail/ThankYou" element={
                      <div>
                          <Scroll/>
                          <NavBar/>
                          <CheckThankYou/>
                          <Footer/>
                      </div>
                  } />

                  <Route path="Setting" element={
                      <div>
                          <Scroll/>
                          <NavBar/>
                          <MediaQuery minWidth={1024}>
                              <div style={{display: "flex", borderBottom: "2px #DCDCDC solid"}}>
                                  <AccountBar/>
                                  <Dashboard/>
                              </div>
                          </MediaQuery>
                          <MediaQuery maxWidth={1024}>
                              <AccountBar/>
                              <Dashboard/>
                          </MediaQuery>
                          <MediaQuery minWidth={1024}>
                              <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", width: "100vw"}}>
                                  <div style={{width: "88vw", display: "flex", justifyContent: "space-between"}}>
                                      <Account/>
                                      <ChangePass/>
                                      <Notif/>
                                  </div>
                              </div>
                              <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", width: "100vw"}}>
                                  <div style={{width: "88vw", display: "flex", justifyContent: "space-between"}}>
                                      <BillAndPay/>
                                      <Colls/>
                                      <Help/>
                                  </div>
                              </div>
                          </MediaQuery>
                          <MediaQuery maxWidth={1024}>
                              <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                  <div style={{height: "10vh"}}></div>
                                  <Account/>
                                  <div style={{height: "3vh"}}></div>
                                  <ChangePass/>
                                  <div style={{height: "3vh"}}></div>
                                  <Notif/>
                                  <div style={{height: "3vh"}}></div>
                                  <BillAndPay/>
                                  <div style={{height: "3vh"}}></div>
                                  <Colls/>
                                  <div style={{height: "3vh"}}></div>
                                  <Help/>
                                  <div style={{height: "20vh"}}></div>
                              </div>
                          </MediaQuery>
                          <Footer/>
                      </div>
                  } />
              </Routes>

          </BrowserRouter>
      );
  }
}

export default App;
