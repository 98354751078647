import Facebook from "./MediaIcons/Facebook";
import Twitter from "./MediaIcons/Twitter";
import Google from "./MediaIcons/Google";
import Instagram from "./MediaIcons/Instagram";
import React from "react";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import {Button, Checkbox, FormControlLabel, FormGroup, IconButton} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import MediaQuery from "react-responsive";

const Login = () => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{display: "flex", height: "89vh", width: "100vw", alignItems: "center", justifyContent: "center"}}>
                    <div style={{height: "70vh", width: "42vw", border: "2px solid #DCDCDC", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <div style={{height: "63vh", width: "34vw", display: "flex", alignItems: "center", flexDirection: "column"}}>
                            <div style={{width: "34vw", height: "8vh", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <div style={{position: "absolute", zIndex: 1}}>
                                    <svg width="4vw" height="4vw">
                                        <rect x="0" y="0" width="4vw" height="4vw" stroke="white" fill="white"/>
                                        <circle cx="2vw" cy="2vw" r="1.5vw" stroke="black" strokeWidth="0.4vw" fill="white" />
                                    </svg>
                                </div>
                                <div style={{width: "34vw", position: "relative", zIndex: -1, top: "4vh"}}>
                                    <ArrowBackRoundedIcon sx={{strokeWidth: "1px", stroke: "#ffffff"}} style={{fontSize: "2.5vw"}}/>
                                </div>
                            </div>
                            <div style={{fontSize: "1.6vw", marginTop: "2.8vh"}}>
                                Login to your Dashboard Account
                            </div>
                            <div style={{width: "34vw", marginTop: "3.5vh", height: "14.5vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <TextField
                                    placeholder="Username/Email"
                                    variant="standard"
                                    sx={{
                                        "& input::placeholder": { fontSize: "1vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                    }}
                                    fullWidth
                                    focused
                                    InputProps={{disableUnderline: true, style:{ borderBottom: "2px solid #323232", height: "5vh", fontSize: "1vw"}}}
                                    InputLabelProps={{style: {color: "#9E9E9E", fontSize: "1vw"}}}
                                />
                                <div>
                                    <TextField
                                        placeholder="Password"
                                        variant="standard"
                                        type={showPassword ? 'text' : 'password'}
                                        sx={{
                                            "& input::placeholder": { fontSize: "1.1vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        focused
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleClickShowPassword} disableRipple="true">
                                                        {showPassword ? <VisibilityOff style={{fontSize: "1.7vw"}}/> : <Visibility style={{fontSize: "1.7vw"}}/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            disableUnderline: true,
                                            style:{ borderBottom: "2px solid #323232", height: "5vh", fontSize: "1.1vw"}
                                        }}
                                    />
                                    <div style={{fontSize: "0.8vw", marginTop: "0.5vh", color: "#A60E0E"}}>Forgot Password</div>
                                </div>
                            </div>
                            <div style={{width: "34vw", marginTop: "1.5vh"}}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked disableRipple/>} label={<div style={{fontSize: "1vw"}}>Remember me</div>}/>
                                </FormGroup>
                            </div>
                            <Button variant="standard" disableRipple style={{textTransform: "capitalize", padding: 0, marginTop: "2vh", fontSize: "1vw", borderRadius: 0, background: "#2F2F2F", color: "#FFFFFF", width: "90%", height: "4vh"}}>
                                Login
                            </Button>
                            <div style={{marginTop: "3vh", fontSize: "1vw"}}>
                                or Login in with
                            </div>
                            <div style={{width: "16vw", display: "flex", justifyContent: "space-between", marginTop: "3vh"}}>
                                <Google/>
                                <Twitter/>
                                <Instagram/>
                                <Facebook/>
                            </div>
                            <div style={{position: "relative", left: "-12vw", fontSize: "1vw", marginTop: "2vh"}}>
                                <span style={{color: "#6A6A6A", marginRight: "0.5vw"}}>Not a User?</span>
                                <span style={{color: "#A60E0E"}}>Register here</span>
                            </div>
                        </div>

                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{display: "flex", height: "89vh", width: "100vw", alignItems: "center", justifyContent: "center"}}>
                    <div style={{height: "70vh", width: "78vw", border: "2px solid #DCDCDC", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <div style={{height: "63vh", width: "60vw", display: "flex", alignItems: "center", flexDirection: "column"}}>
                            <div style={{width: "57vw", height: "8vh", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <div style={{position: "absolute", zIndex: 1}}>
                                    <svg width="7vw" height="7vw">
                                        <rect x="0" y="0" width="7vw" height="7vw" stroke="white" fill="white"/>
                                        <circle cx="3.5vw" cy="3.5vw" r="2vw" stroke="black" strokeWidth="0.4vw" fill="white" />
                                    </svg>
                                </div>
                                <div style={{width: "60vw", position: "relative", zIndex: -1, top: "2vh"}}>
                                    <ArrowBackRoundedIcon sx={{strokeWidth: "1px", stroke: "#ffffff"}} style={{fontSize: "3.5vw"}}/>
                                </div>
                            </div>
                            <div style={{fontSize: "2.9vw", marginTop: "2.8vh"}}>
                                Login to your Dashboard Account
                            </div>
                            <div style={{width: "57vw", marginTop: "3.5vh", height: "14.5vh", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                                <TextField
                                    placeholder="Username/Email"
                                    variant="standard"
                                    sx={{
                                        "& input::placeholder": { fontSize: "2.1vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                    }}
                                    fullWidth
                                    focused
                                    InputProps={{disableUnderline: true, style:{ borderBottom: "2px solid #323232", height: "5vh", fontSize: "1vw"}}}
                                    InputLabelProps={{style: {color: "#9E9E9E", fontSize: "1vw"}}}
                                />
                                <div>
                                    <TextField
                                        placeholder="Password"
                                        variant="standard"
                                        type={showPassword ? 'text' : 'password'}
                                        sx={{
                                            "& input::placeholder": { fontSize: "2.1vw", color: "#6A6A6A", fontStyle: "normal", opacity: 1},
                                        }}
                                        fullWidth
                                        focused
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleClickShowPassword} disableRipple="true">
                                                        {showPassword ? <VisibilityOff style={{fontSize: "2.8vw"}}/> : <Visibility style={{fontSize: "2.8vw"}}/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            disableUnderline: true,
                                            style:{ borderBottom: "2px solid #323232", height: "5vh", fontSize: "1.1vw"}
                                        }}
                                    />
                                    <div style={{fontSize: "1.5vw", marginTop: "0.5vh", color: "#A60E0E"}}>Forgot Password</div>
                                </div>
                            </div>
                            <div style={{width: "57vw", marginTop: "1.5vh"}}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked disableRipple/>} label={<div style={{fontSize: "1.8vw"}}>Remember me</div>}/>
                                </FormGroup>
                            </div>
                            <Button variant="standard" disableRipple style={{textTransform: "capitalize", padding: 0, marginTop: "1.9vh", fontSize: "2vw", borderRadius: 0, background: "#2F2F2F", color: "#FFFFFF", width: "90%", height: "4vh"}}>
                                Login
                            </Button>
                            <div style={{marginTop: "3vh", fontSize: "1.9vw"}}>
                                or Login in with
                            </div>
                            <div style={{width: "25vw", display: "flex", justifyContent: "space-between", marginTop: "3vh"}}>
                                <Google/>
                                <Twitter/>
                                <Instagram/>
                                <Facebook/>
                            </div>
                            <div style={{position: "relative", left: "-20vw", fontSize: "1.85vw", marginTop: "2vh"}}>
                                <span style={{color: "#6A6A6A", marginRight: "0.5vw"}}>Not a User?</span>
                                <span style={{color: "#A60E0E"}}>Register here</span>
                            </div>
                        </div>

                    </div>
                </div>
            </MediaQuery>
        </div>

    )
}

export default Login;