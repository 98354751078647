import React from "react";
import {Button} from "@mui/material";
import img from "./img/img_join.png";
import img_mobile from "./img/img_join_mobile.png"
import MediaQuery from "react-responsive";

const Join = () => {
    return (
        <div>
            <MediaQuery minWidth={1024}>
                <div style={{backgroundImage: `url(${img})`, backgroundSize: "100%", width: "100vw", height: "100vh", position: "absolute", zIndex: -1, filter: "brightness(75%)"}}>
                </div>
                <div style={{width: "100vw", height: "100vh", display: "flex", flexDirection: "column", justifyContent:"center"}}>
                    <div style={{color: "white"}}>
                        <div style={{fontSize: "3.5vw", marginBottom: "12vh", textAlign: "center"}}>Join Our Gallery</div>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <div style={{fontSize: "1.5vw", width: "30vw", textAlign: "center"}}>
                                Access exclusive art exhibitions and pieces curated just for you.
                            </div>
                        </div>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Button variant="standard" disableRipple style={{textTransform: "capitalize", marginTop: "6vh", fontSize: "1.1vw", borderRadius: 0, background: "#D9D9D9", color: "#2F2F2F", width: "11vw", height: "5vh"}}>
                                Create Account
                            </Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <div style={{backgroundImage: `url(${img})`, backgroundSize: "100%", width: "100vw", height: "100vh", position: "absolute", zIndex: -1, filter: "brightness(75%)"}}>
                </div>
                <div style={{width: "100vw", height: "100vh", display: "flex", flexDirection: "column", justifyContent:"center"}}>
                    <div style={{color: "white"}}>
                        <div style={{fontSize: "6.1vw", marginBottom: "12vh", textAlign: "center"}}>Join Our Gallery</div>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <div style={{fontSize: "3vw", width: "60vw", textAlign: "center"}}>
                                Access exclusive art exhibitions and pieces curated just for you.
                            </div>
                        </div>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Button variant="standard" disableRipple style={{textTransform: "capitalize", marginTop: "6vh", fontSize: "2.3vw", borderRadius: 0, background: "#D9D9D9", color: "#2F2F2F", width: "23vw", height: "5vh"}}>
                                Create Account
                            </Button>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default Join;