import {useGoogleLogin} from "@react-oauth/google";
import MediaQuery from "react-responsive";

const Google = () => {
    const googleHandler = useGoogleLogin({
        onSuccess: (codeResponse) => {console.log('LoginAndRegister Succeeded: ', codeResponse);},
        onError: (error) => console.log('LoginAndRegister Failed:', error)
    });

    return (
        <div>
            <MediaQuery minWidth={1024}>
                <svg onClick={googleHandler} fill="#000000" height="1.7vw" width="1.7vw" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M473.16,221.48l-2.26-9.59H262.46v88.22H387c-12.93,61.4-72.93,93.72-121.94,93.72-35.66,0-73.25-15-98.13-39.11a140.08,140.08,0,0,1-41.8-98.88c0-37.16,16.7-74.33,41-98.78s61-38.13,97.49-38.13c41.79,0,71.74,22.19,82.94,32.31l62.69-62.36C390.86,72.72,340.34,32,261.6,32h0c-60.75,0-119,23.27-161.58,65.71C58,139.5,36.25,199.93,36.25,256S56.83,369.48,97.55,411.6C141.06,456.52,202.68,480,266.13,480c57.73,0,112.45-22.62,151.45-63.66,38.34-40.4,58.17-96.3,58.17-154.9C475.75,236.77,473.27,222.12,473.16,221.48Z"/>
                </svg>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <svg onClick={googleHandler} fill="#000000" height="2.5vw" width="2.5vw" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M473.16,221.48l-2.26-9.59H262.46v88.22H387c-12.93,61.4-72.93,93.72-121.94,93.72-35.66,0-73.25-15-98.13-39.11a140.08,140.08,0,0,1-41.8-98.88c0-37.16,16.7-74.33,41-98.78s61-38.13,97.49-38.13c41.79,0,71.74,22.19,82.94,32.31l62.69-62.36C390.86,72.72,340.34,32,261.6,32h0c-60.75,0-119,23.27-161.58,65.71C58,139.5,36.25,199.93,36.25,256S56.83,369.48,97.55,411.6C141.06,456.52,202.68,480,266.13,480c57.73,0,112.45-22.62,151.45-63.66,38.34-40.4,58.17-96.3,58.17-154.9C475.75,236.77,473.27,222.12,473.16,221.48Z"/>
                </svg>
            </MediaQuery>
        </div>
    )
}

export default Google;