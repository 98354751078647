import * as React from 'react';
import Button from '@mui/material/Button';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import "./NavBar_styles.css";
import {useNavigate} from "react-router-dom";
import MediaQuery from 'react-responsive';
import ShopSideBar from "../Shop/ShopSideBar";

const navItems = ['Artists', 'Exhibitions', 'Collect'];

function DrawerAppBar() {
    let navigate = useNavigate();

    const handleClick = (item) => {
        navigate('/'+item);
    }

    function openNav(id) {
        document.getElementById(id).style.width = "31vw";
    }

    function closeNav() {
        document.getElementById("mySidebar").style.width = "0px";

    }

    return (
        <div>
            <MediaQuery maxWidth={1024}>
                <div id="mySidebar" className="sidebar">
                    <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>×</a>
                    <a onClick={()=>handleClick("Artists")}>Artists</a>
                    <a onClick={()=>handleClick("Exhibitions")}>Exhibitions</a>
                    <a onClick={()=>handleClick("Collect")}>Collect</a>
                    <div>
                        <ShoppingBagOutlinedIcon onClick={()=>handleClick("Shop")} style={{color: "#2F2F2F", transform: "scale(1.9)", fontSize: "2.7vh", marginRight: "4vw", stroke: "#F3F3F3"}}/>
                        <AccountCircleOutlinedIcon sx={{color: "#2F2F2F", transform: "scale(1.9)", fontSize: "2.7vh", stroke: "#F3F3F3"}}/>
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", height: "9vh", width: "100vw", borderBottom: "1px #DCDCDC solid", position: "relative"}}>
                    <Button disableRipple onClick={()=>handleClick('')} style={{color: "#2F2F2F", backgroundColor: "transparent", fontSize: "4.3vw", marginLeft:"3vw"}}>
                        OCTAVATE
                    </Button>
                    <div style={{marginRight:"2.3vw", display:"flex", alignItems: "center"}}>
                        <MenuOutlinedIcon onClick={()=>openNav('mySidebar')} style={{cursor: "pointer", color: "#2F2F2F", fontSize: "6.5vw", stroke: "#ffffff"}}/>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <ShopSideBar/>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", height: "11vh", width: "100vw", borderBottom: "1px #DCDCDC solid", position: "relative"}}>
                    <Button disableRipple style={{color: "#2F2F2F", backgroundColor: "transparent", fontSize: "2.3vw", marginLeft:"2vw"}}>
                        OCTAVATE
                    </Button>
                    <div style={{marginRight:"2.8vw", display:"flex", alignItems: "center"}}>
                        <div>
                            {navItems.map((item) => (
                                <Button className="AppBar_Box" disableRipple onClick={()=>handleClick(item)} style={{color: "#2F2F2F", backgroundColor: "transparent", fontSize: "1.5vw", marginRight: "3.5vw", textTransform: 'capitalize'}}
                                >
                                    {item}
                                </Button>
                            ))}
                        </div>
                        <ShoppingBagOutlinedIcon onClick={()=>openNav('shopSlider')} style={{color: "#2F2F2F", transform: "scale(1.8)", fontSize: "1.6vw", marginRight: "2.3vw", stroke: "#ffffff"}}/>
                        <AccountCircleOutlinedIcon sx={{color: "#2F2F2F", transform: "scale(1.8)", fontSize: "1.6vw", stroke: "#ffffff"}}/>
                    </div>
                </div>
            </MediaQuery>
        </div>
    );
}

export default DrawerAppBar;