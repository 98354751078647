import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import {Button} from "@mui/material";
import * as React from "react";
import img1 from './img/sidebar_img1.png';
import {useState} from "react";
import {useNavigate} from "react-router-dom";

const AddMinus = () => {
    const [amount, setAmount] = useState(0);
    return (
        <div style={{display: "flex"}}>
            <div style={{width: "1.5vw", height: "1.5vw", backgroundColor: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <RemoveIcon onClick={()=>{if (amount===0) {setAmount(0)} else {setAmount(amount-1)}}} style={{fontSize: "0.8vw", color: "#A1A1A1"}}/>
            </div>
            <div style={{width: "1.2vw", height: "1.5vw", fontSize: "0.8vw", backgroundColor: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
                {amount}
            </div>
            <div style={{width: "1.5vw", height: "1.5vw", backgroundColor: "#F3F3F3", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <AddIcon onClick={()=>setAmount(amount+1)} style={{fontSize: "0.8vw", color: "#A1A1A1"}}/>
            </div>
        </div>
    )
}

const ShopSideBar = () => {
    const navigate = useNavigate()
    function openNav() {
        document.getElementById("shopSlider").style.width = "31vw";
    }

    function closeNav() {
        console.log(document.getElementById("shopSlider").style.width);
        document.getElementById("shopSlider").style.width = "0px";
        console.log(document.getElementById("shopSlider").style.width);
    }

    const handleClick = (item) => {
        navigate('/'+item);
    }

    return (
        <div>
            <div id={"shopSlider"} style={{overflowX: "hidden", transition: "0.5s", position: "fixed", right: 0, width: 0, height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "white", zIndex: 1}}>
                <div style={{width: "90%", height: "2.5vh", display: "flex", marginTop: "2vh", justifyContent: "end"}}>
                    <CloseIcon onClick={closeNav} style={{color: "#BABABA", fontSize: "1.8vw"}}/>
                </div>
                <div style={{width: "90%", height: "61.5vh"}}>
                    <Button disableRipple variant="outlined" startIcon={<ArrowBackIosOutlinedIcon style={{fontSize: "15px"}}/>} style={{backgroundColor: "transparent", paddingTop: "0.2vh", paddingBottom: "0.2vh", paddingLeft: "0.8vw", paddingRight: "0.8vw", textTransform: "capitalize", fontSize: "0.8vw", borderRadius: 0, border: "2px solid #E3E3E3", color: "#696969"}}>
                        Continue Shopping
                    </Button>
                    <div style={{width: "26vw", textAlign: "center", fontSize: "1.3vw", fontWeight: "bold", marginTop: "3vh", marginBottom: "3vh"}}>Shopping Cart</div>
                    <div style={{display: "flex", fontSize: "0.95vw"}}>
                        <div style={{width: "6.2vw", textAlign: "center"}}>
                            Product
                        </div>
                        <div style={{width: "6.2vw", textAlign: "center"}}>
                            Price
                        </div>
                        <div style={{width: "6.2vw", textAlign: "center"}}>
                            Quantity
                        </div>
                        <div style={{width: "6.2vw", textAlign: "center"}}>
                            Total
                        </div>
                    </div>
                    <hr/>
                    <div>
                        <div style={{display: "flex", alignItems: "center", marginTop: "3vh"}}>
                            <div style={{width: "6.2vw", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <div style={{height: "9.5vh", width: "6vw", backgroundColor: "#F3F3F3", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                    <img src={img1} style={{width: "4.2vw", height: "5.5vh", marginTop: "1.5vh"}}/>
                                    <div style={{marginTop: "0.3vh", fontSize: "0.65vw"}}>Piece Title</div>
                                </div>
                            </div>
                            <div style={{width: "6.2vw", fontSize: "0.9vw", textAlign: "center"}}>$346</div>
                            <div style={{width: "6.2vw", display: "flex", justifyContent: "center"}}>
                                <AddMinus/>
                            </div>
                            <div style={{width: "6.2vw", fontSize: "0.9vw", textAlign: "center"}}>$692</div>
                            <CloseIcon style={{color: "#BABABA", fontSize: "1.2vw"}}/>
                        </div>
                        <div style={{display: "flex", alignItems: "center", marginTop: "2vh"}}>
                            <div style={{width: "6.2vw", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <div style={{height: "9.5vh", width: "6vw", backgroundColor: "#F3F3F3", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                    <img src={img1} style={{width: "4.2vw", height: "5.5vh", marginTop: "1.5vh"}}/>
                                    <div style={{marginTop: "0.3vh", fontSize: "0.65vw"}}>Piece Title</div>
                                </div>
                            </div>
                            <div style={{width: "6.2vw", fontSize: "0.9vw", textAlign: "center"}}>$346</div>
                            <div style={{width: "6.2vw", display: "flex", justifyContent: "center"}}>
                                <AddMinus/>
                            </div>
                            <div style={{width: "6.2vw", fontSize: "0.9vw", textAlign: "center"}}>$692</div>
                            <CloseIcon style={{color: "#BABABA", fontSize: "1.2vw"}}/>
                        </div>
                        <div style={{display: "flex", alignItems: "center", marginTop: "2vh"}}>
                            <div style={{width: "6.2vw", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <div style={{height: "9.5vh", width: "6vw", backgroundColor: "#F3F3F3", display: "flex", flexDirection: "column", alignItems: "center"}}>
                                    <img src={img1} style={{width: "4.2vw", height: "5.5vh", marginTop: "1.5vh"}}/>
                                    <div style={{marginTop: "0.3vh", fontSize: "0.65vw"}}>Piece Title</div>
                                </div>
                            </div>
                            <div style={{width: "6.2vw", fontSize: "0.9vw", textAlign: "center"}}>$346</div>
                            <div style={{width: "6.2vw", display: "flex", justifyContent: "center"}}>
                                <AddMinus/>
                            </div>
                            <div style={{width: "6.2vw", fontSize: "0.9vw", textAlign: "center"}}>$692</div>
                            <CloseIcon style={{color: "#BABABA", fontSize: "1.2vw"}}/>
                        </div>
                    </div>
                </div>
                <div style={{height: "34vh", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#F3F3F3"}}>
                    <div style={{width: "85%", marginTop: "3vh"}}>
                        <div style={{fontSize: "1.3vw", width: "18vw", color: "#2F2F2F"}}>Order Summary</div>
                        <div style={{fontSize: "0.95vw", width: "100%", height: "25vh"}}>
                            <div style={{marginBottom: "1.2vh", marginTop: "1.5vh"}}>
                                <span style={{color: "#696969", marginRight: "0.6vw"}}>Item:</span>
                                <span style={{color: "#2F2F2F"}}>3</span>
                            </div>
                            <div style={{marginBottom: "1.2vh"}}>
                                <span style={{color: "#696969", marginRight: "0.6vw"}}>Subtotal:</span>
                                <span style={{color: "#2F2F2F"}}>$1048</span>
                            </div>
                            <div style={{marginBottom: "1.2vh"}}>
                                <span style={{color: "#696969", marginRight: "0.6vw"}}>Discount:</span>
                                <span style={{textDecoration: "line-through", color: "#9E9E9E", marginRight: "0.6vw"}}>$1048</span>
                                <span style={{color: "#2F2F2F"}}>$948</span>
                            </div>
                            <div style={{marginBottom: "3.5vh"}}>
                                <span style={{color: "#696969", marginRight: "0.6vw"}}>Shipping:</span>
                                <span style={{color: "#2F2F2F"}}>+$10</span>
                            </div>
                            <hr style={{width: "100%"}}/>
                            <div style={{marginTop: "1.5vh", display: "flex", justifyContent: "space-between"}}>
                                <div>
                                    <span style={{color: "#696969", marginRight: "1vw"}}>Total:</span>
                                    <span style={{color: "#2F2F2F"}}>$958</span>
                                </div>
                                <Button onClick={()=>handleClick("Checkout/Detail")} disableRipple disableElevation variant="contained" style={{backgroundColor: "transparent", borderRadius: "0", background: "#2F2F2F", textTransform:"capitalize", fontSize: "0.9vw"}}>
                                    Check Out
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShopSideBar;